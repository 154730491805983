export const USER = {
  handlers: {
    login: 'USER_LOGIN_HANDLER',
    logs: 'USER_LOGS_HANDLER',
  },
  update: 'USER_UPDATE',
};

export const CATALOGS = {
  handlers: {
    system: 'CATALOGS_QUERY_SYSTEM_HANDLER',
  },
  system: 'CATALOGS_SYSTEM',
};

export const EDUPLAN = {
  handlers: {
    import: 'EDUPLAN_IMPORT_HANDLER',
  },
  update: 'EDUPLAN_UPDATE',
};

export const SETTINGS = {
  update: 'UPDATE_SETTINGS',
};

export const SIGN_OUT = 'SIGN_OUT';
