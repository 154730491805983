import { nanoid } from 'nanoid';

export const arrayToCollection = (arr, id = 'id', options = {}) => {
  const result = {};
  arr.forEach((item) => {
    if (item[id]) {
      result[item[id]] = {};
      if (options.preserve) result[item[id]][id] = item[id];
      Object.entries(item).forEach(([key, value]) => {
        if (key !== id && value !== null) {
          if (!(options.skip ?? []).includes(key)) result[item[id]][key] = value;
        }
      });
    }
  });
  return result;
};

export const getUniqueID = (obj = {}, size = 21) => {
  const id = nanoid(size);
  if (!obj[id]) return id;
  return getUniqueID(obj, size);
};

export const getUniqueElement = (arr = [], size = 21) => {
  const id = nanoid(size);
  if (!arr.includes(id)) return id;
  return getUniqueElement(arr, size);
};
