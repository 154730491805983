import { Notification } from '@xbotvn/mui';
import { getAuth, signOut } from 'firebase/auth';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { SIGN_OUT, USER } from './constants';

function* update(data = {}) {
  yield put({
    type: USER.update,
    ...data,
  });
}

function userSignedOut() {
  signOut(getAuth());
}

function* userSignedIn(user) {
  const { email, uid } = user;
  if (email) {
    try {
      yield* update({
        uid,
        email,
        displayName: user?.displayName ?? email,
        photoURL: user?.photoURL ?? '',
        unit: {
          classroom: ['Lớp A', 'Lớp B', 'Lớp C'],
        },
        authorized: true,
      });
    } catch ({ message }) {
      yield* update({ authorized: true });
      yield* userSignedOut();
      Notification.error(message);
    }
  } else {
    yield* update({ authorized: true });
  }
}

export const handleUserSignedIn = (user) => ({
  type: USER.handlers.login,
  ...user,
});

export const handleUserSignOut = () => ({
  type: SIGN_OUT,
});

export default function* saga() {
  yield all([
    yield takeLatest(USER.handlers.login, userSignedIn),
    yield takeEvery(SIGN_OUT, userSignedOut),
  ]);
}
