import { cloneDeep, get, set, unset } from 'lodash';

import { EDUPLAN } from '../actions/constants';

const initialState = {};

export default function eduplan(state = initialState, action) {
  const { type, path, data } = action;
  if (type === EDUPLAN.handlers.import) {
    return {
      handling: true,
    };
  }
  if (type === EDUPLAN.update) {
    if (path) {
      const newData = cloneDeep(state.data || {});
      if (data) set(newData, path, { ...get(newData, path, {}), ...data });
      else unset(newData, path);
      return {
        data: newData,
        handling: false,
      };
    }
    return {
      data,
      handling: false,
    };
  }
  return state;
}
