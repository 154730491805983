import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import {
  AppBar as MAppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@xbotvn/mui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { handleUserSignOut } from '../../redux/actions/user';

function AppBar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { email, photoURL, displayName } = useSelector(({ user }) => user);

  if (!email) return null;
  return (
    <MAppBar position="static" color="transparent">
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', width: 300, alignItems: 'center' }}>
          <img style={{ width: 50, height: 'auto' }} src="/icon-192x192.png" alt="logo" />
          <Typography variant="h6">EDUBOT123</Typography>
        </Box>
        <Stack spacing={1} direction="row" sx={{ flexGrow: 1 }}>
          <Button variant="text" onClick={() => history.push('/')}>
            Kế hoạch giáo dục
          </Button>
          <Button variant="text" onClick={() => history.push('/timetable')}>
            Thời khoá biểu
          </Button>
        </Stack>
        <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
          <Avatar alt={displayName} src={photoURL} />
          <Typography>{displayName}</Typography>
          <IconButton onClick={() => dispatch(handleUserSignOut())}>
            <UilSignout />
          </IconButton>
        </Stack>
      </Toolbar>
    </MAppBar>
  );
}

export default AppBar;
