import { CATALOGS, SIGN_OUT } from '../actions/constants';

const initialState = {
  system: {},
  app: {},
};

export default function catalogs(state = initialState, action) {
  const { system } = state;
  const { id, data } = action;

  switch (action.type) {
    case CATALOGS.handlers.system:
      return {
        ...state,
        system: {
          ...system,
          [id]: {
            handling: true,
          },
        },
      };
    case CATALOGS.system:
      return {
        ...state,
        system: {
          ...system,
          [id]: {
            data: data || (system?.[id]?.data ?? {}),
            handling: false,
          },
        },
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
