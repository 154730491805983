import { Notification } from '@xbotvn/mui';
import { set, trimEnd } from 'lodash';
import { all, put, takeEvery } from 'redux-saga/effects';
import XLSX from 'xlsx';

import { getUniqueID } from '../../libs/utils';
import { EDUPLAN } from './constants';

function* update(path, data = {}) {
  yield put({
    type: EDUPLAN.update,
    path,
    data,
  });
}

function generateIDs(data) {
  const output = {};
  Object.values(data).forEach(({ name, contents, ...rest }) => {
    const id = getUniqueID();
    output[id] = { name };
    if (contents) {
      output[id].contents = {};
      contents.forEach((content) => {
        output[id].contents[getUniqueID({}, 10)] = {
          name: content,
        };
      });
    }
    output[id].children = generateIDs(rest);
  });
  return output;
}

function* importEduplan({ data, source }) {
  if (source === 'xlsx') {
    const wb = XLSX.read(data, { type: 'binary' });
    const sheets = {};
    try {
      wb.SheetNames.forEach((sheetName) => {
        sheets[sheetName] = {};
        const rows = Object.values(
          XLSX.utils.sheet_to_json(wb.Sheets[sheetName], { header: 1 }) || {}
        ).slice(2);
        let prevKey;
        let childIndex;
        rows.forEach(([colA, colB]) => {
          const parsedKey = `${colA}`.match(/^\d(.\d)*/g)?.[0];
          if (parsedKey) {
            set(sheets, `${sheetName}.${parsedKey}.name`, colB);
            prevKey = parsedKey;
            childIndex = 1;
          } else {
            set(sheets, `${sheetName}.${prevKey}.${childIndex}.name`, colA);
            set(
              sheets,
              `${sheetName}.${prevKey}.${childIndex}.contents`,
              `${colB}`.split('\n').map((content) => trimEnd(content, '\r'))
            );
            childIndex += 1;
          }
        });
        sheets[sheetName] = generateIDs(sheets[sheetName]);
      });
    } catch ({ message }) {
      Notification.error(message);
    }
    yield update('', sheets);
  } else yield update('', JSON.parse(data));
}

export const handleImport = (data, source = 'xlsx') => ({
  type: EDUPLAN.handlers.import,
  data,
  source,
});

export const handleUpdate = (path, data) => ({
  type: EDUPLAN.update,
  path,
  data,
});

export default function* saga() {
  yield all([yield takeEvery(EDUPLAN.handlers.import, importEduplan)]);
}
