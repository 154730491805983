import './libs/firebase';

import UilBookOpen from '@iconscout/react-unicons/icons/uil-book-open';
import UilCommentQuestion from '@iconscout/react-unicons/icons/uil-comment-question';
import UilDesktop from '@iconscout/react-unicons/icons/uil-desktop';
import { Box, Button, Notification, Typography, Waiting } from '@xbotvn/mui';
import { ThemeProvider } from '@xbotvn/mui/styles';
import { lightTheme } from '@xbotvn/mui/themes';
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth';
import React, { Suspense, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch as RouterSwitch } from 'react-router-dom';

import packageJson from '../package.json';
import AuthorizedRoute from './AuthorizedRoute';
import { AppBar } from './components';
import { handleUpdateSettings } from './redux/actions/settings';
import { handleUserSignedIn } from './redux/actions/user';

const Welcome = React.lazy(() => import('./screens/Welcome'));
const Home = React.lazy(() => import('./screens/Home'));
const TimeTable = React.lazy(() => import('./screens/TimeTable'));
const NotFound = React.lazy(() => import('./screens/NotFound'));

export default function App() {
  const dispatch = useDispatch();
  const { authorized, logged } = useSelector(({ user }) => ({
    authorized: user?.authorized ?? false,
    logged: user.email,
  }));

  useEffect(() => {
    const cached = localStorage.getItem('settings');
    if (cached) {
      const parsed = JSON.parse(cached);
      dispatch(handleUpdateSettings(parsed));
      if (parsed.dark) {
        document.body.style.backgroundColor = '#30404d';
      }
    }
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        Notification.warn('Link đã được mở trên một thiết bị khác. Xin vui lòng đăng nhập lại.');
      } else {
        signInWithEmailLink(getAuth(), email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch((err) => {
            Notification.error(err.message);
          });
      }
    }
    return onAuthStateChanged(getAuth(), (user) => {
      dispatch(handleUserSignedIn(user));
    });
  }, []);

  const ready = useMemo(() => authorized, [authorized]);

  return ready ? (
    <ThemeProvider theme={lightTheme}>
      <BrowserRouter>
        <Suspense fallback={<Waiting />}>
          <AppBar />
          <RouterSwitch>
            <Route exact path="/welcome">
              <Welcome />
            </Route>
            <AuthorizedRoute exact path="/timetable" component={TimeTable} />
            <AuthorizedRoute exact path="/" component={Home} />
            <Route component={() => <NotFound />} />
          </RouterSwitch>
        </Suspense>
      </BrowserRouter>
      <Box
        zIndex={998}
        height={50}
        bgcolor="rgb(255, 255, 255)"
        p={1}
        position="fixed"
        right={0}
        bottom={0}
        left={0}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="caption">
          {`EDUBOT123 v${packageJson.version} - © 2021, XBOT Technology JSC. All Rights Reserved.`}
        </Typography>
        {logged ? (
          <Box display="flex">
            <Button
              size="small"
              variant="text"
              style={{ marginRight: 20 }}
              startIcon={<UilDesktop />}
            >
              Hỗ trợ từ xa
            </Button>
            <Button
              variant="text"
              onClick={() =>
                window.open('https://www.notion.so', 'Hướng dẫn sử dụng', 'location:no')
              }
              style={{ marginRight: 20 }}
              startIcon={<UilBookOpen />}
              size="small"
            >
              Sách hướng dẫn
            </Button>
            {window.fcWidget ? (
              <Button
                size="small"
                endIcon={<UilCommentQuestion />}
                onClick={() => window.fcWidget.open()}
                variant="outlined"
                color="primary"
              >
                Chăm sóc
              </Button>
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Notification.Container />
    </ThemeProvider>
  ) : (
    <Waiting />
  );
}
