import { Editor } from '@tinymce/tinymce-react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

function RTEditor({ onChange, value, id, height }) {
  const debounceOnChange = useCallback(
    debounce((val) => onChange(val), 500),
    []
  );

  return (
    <Editor
      key={id}
      initialValue={value}
      onEditorChange={debounceOnChange}
      init={{
        plugins:
          'colorpicker media contextmenu link paste save textcolor autolink fullscreen image insertdatetime lists preview searchreplace table',
        language: 'vi_VN',
        menubar: 'edit insert format table',
        toolbar: [
          'undo redo | cut copy paste | styleselect | bold italic | alignleft aligncenter alignright | link image',
          'bullist numlist | indent outdent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | fullscreen',
        ],
        statusbar: false,
        height,
      }}
    />
  );
}

RTEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  height: PropTypes.number,
};

RTEditor.defaultProps = {
  value: '',
  onChange: () => {},
  height: 400,
};

export default RTEditor;
