import { combineReducers } from 'redux';

import catalogs from './catalogs';
import eduplan from './eduplan';
import settings from './settings';
import user from './user';

const rootReducer = combineReducers({
  settings,
  catalogs,
  user,
  eduplan,
});

export default rootReducer;
