import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

function AuthorizedRoute({ component: Component, path }) {
  const { email, authorized } = useSelector(({ user }) => user);

  if (authorized && !email)
    return <Route exact path={path} render={() => <Redirect to={{ pathname: '/welcome' }} />} />;

  return <Route exact path={path} render={(props) => <Component {...props} />} />;
}

AuthorizedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.any.isRequired,
};

export default AuthorizedRoute;
